import { useEffect, useState, useRef, useContext } from 'react'
import Slider from 'react-slick'
import Image from 'next/image'
import { A11yText, Icon, ResponsivePropArgs, useResponsiveProp } from '@telus-uds/components-web'
import { PlaySolid, Pause } from '@telus-uds/palette-allium/build/web/icons'
import { SiteBuilderContext } from '@/siteBuilder/renderer/context/SiteBuilderContext'
import { Language } from '@/siteBuilder/locale'
import { contentfulLoader } from '@/siteBuilder/blocks/core/Image/contentfulLoader'
import { StyledSlideshow, ToggleButton } from './styles'
import 'slick-carousel/slick/slick.css'

export interface IHealthSlideshowProps {
  images: string[]
  minHeight?: ResponsivePropArgs<number>
  fullWidth?: boolean
  extendMode?: 'left' | 'right' | 'no'
  imageDuration: number
  crossfadeDuration: number
}

const HealthSlideshow = ({
  images,
  minHeight = { md: 400, xl: 525 },
  fullWidth = false,
  extendMode = 'no',
  imageDuration = 2500,
  crossfadeDuration = 250,
}: IHealthSlideshowProps) => {
  const sliderRef = useRef<Slider | null>(null)
  const [checkedMotionPreference, setCheckedMotionPreference] = useState<boolean>(false)
  const [isPlaying, setIsPlaying] = useState<boolean>(true)

  const { locale, isWebpSupported } = useContext(SiteBuilderContext)
  const language = locale.language || 'en'
  const webpLoader = contentfulLoader(isWebpSupported)

  const getMinHeights = ({ md, lg = md, xl = lg }: ResponsivePropArgs<number>) => ({ md, lg, xl })
  const minHeights = getMinHeights(minHeight)

  const toggleIconSize = useResponsiveProp({ xs: 'micro', lg: 'normal' })
  const toggleLabel: Record<Language, string> = {
    en: 'toggle playback',
    fr: 'basculer la lecture',
  }

  useEffect(() => {
    if (!checkedMotionPreference) {
      const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches
      if (prefersReducedMotion && isPlaying) {
        setIsPlaying(false)
      }
      setCheckedMotionPreference(true)
    }
  }, [])

  useEffect(() => {
    if (!sliderRef.current) return
    if (isPlaying) sliderRef.current.slickPlay()
    else sliderRef.current.slickPause()
  }, [isPlaying])

  const handleToggleClick = () => {
    setIsPlaying(!isPlaying)
  }

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: crossfadeDuration,
    autoplaySpeed: imageDuration,
    cssEase: 'linear',
    swipe: false,
  }

  return (
    <StyledSlideshow minHeight={minHeights} fullWidth={fullWidth} extendMode={extendMode} data-testid="healthSlideshow">
      <div>
        <Slider {...sliderSettings} ref={sliderRef}>
          {images.map((src, index) => (
            <Image
              key={index}
              src={src}
              loader={webpLoader}
              placeholder="blur"
              blurDataURL={webpLoader({ src, width: 10 })}
              priority={index === 0}
              alt=""
              layout="fill"
              objectFit="cover"
              style={{ display: 'block' }}
              data-testid="healthSlideshow-image"
            />
          ))}
        </Slider>
        <ToggleButton
          type="button"
          onClick={handleToggleClick}
          data-testid={'healthSlideshow-toggleButton-' + (isPlaying ? 'playing' : 'paused')}
        >
          <Icon icon={isPlaying ? Pause : PlaySolid} variant={{ size: toggleIconSize, color: 'inverse' }} />
          <A11yText text={toggleLabel[language]} />
        </ToggleButton>
      </div>
    </StyledSlideshow>
  )
}

export default HealthSlideshow
