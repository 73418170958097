const PREVIEW_HOST_URL = 'preview.contentful.com'

const PROD_ENVIRONMENT = 'production'

type articlesPerViewPortType = { desktop: number; tablet: number; mobile: number }

type articleConfigType = {
  INITIAL_LOAD: number
  ARTICLES_PER_PAGE: {
    PAGINATION: articlesPerViewPortType
    'SHOW MORE': articlesPerViewPortType
  }
  DESKTOP: 'desktop'
  TABLET: 'tablet'
  MOBILE: 'mobile'
}

const ARTICLES_CONFIG: articleConfigType = {
  INITIAL_LOAD: 12,
  ARTICLES_PER_PAGE: {
    PAGINATION: {
      desktop: 9,
      tablet: 6,
      mobile: 6,
    },
    'SHOW MORE': {
      desktop: 6,
      tablet: 4,
      mobile: 2,
    },
  },
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
}

const ANALYTICS_EVENT_CLICK = 'click'
const ANALYTICS_PARAMETER_TYPE_BLOG = 'blog'
const ANALYTICS_DATALAYER_BRAND = 'consumer'
const ANALYTICS_DATALAYER_SITE_GENERATOR = 'blogBuilder'

const DEFAULT_LOCALE = 'en-BC'

const SIZES = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

const SOCIAL_BLOCK_ICONS_DEFINITION = {
  NONE: 'None',
  LINKED_IN: 'LinkedIn',
  TWITTER: 'Twitter',
  FACEBOOK: 'Facebook',
  MEDIUM: 'Medium',
  INSTAGRAM: 'Instagram',
}

export {
  PREVIEW_HOST_URL,
  PROD_ENVIRONMENT,
  ARTICLES_CONFIG,
  ANALYTICS_EVENT_CLICK,
  ANALYTICS_PARAMETER_TYPE_BLOG,
  ANALYTICS_DATALAYER_BRAND,
  ANALYTICS_DATALAYER_SITE_GENERATOR,
  DEFAULT_LOCALE,
  SIZES,
  SOCIAL_BLOCK_ICONS_DEFINITION,
}
