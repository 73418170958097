import { IHealthSlideshowFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import HealthSlideshow from './HealthSlideshow'

const ContentfulHealthSlideshow = withContentful<IHealthSlideshowFields>(HealthSlideshow, {
  images: (props) => props.fields.images.map((image) => image.fields.file.url),
  minHeight: (props) => ({
    md: props.fields.mdMinHeight,
    lg: props.fields.lgMinHeight,
    xl: props.fields.xlMinHeight,
  }),
  fullWidth: (props) => props.fields.fullWidth,
  extendMode: (props) => props.fields.extendMode,
  imageDuration: (props) => props.fields.imageDuration,
  crossfadeDuration: (props) => props.fields.crossfadeDuration,
})

export default ContentfulHealthSlideshow
