import styled, { css } from 'styled-components'
import { ResponsivePropArgs } from '@telus-uds/components-web'
import { SIZES } from '@/blogBuilder/utils/constants'

const GUTTER_WIDTH = 16
const GUTTER_WIDTH_2X = GUTTER_WIDTH * 2
const SCROLLBAR_WIDTH = 15
const VIEWPORT = {
  sm: SIZES.sm + SCROLLBAR_WIDTH,
  md: SIZES.md + SCROLLBAR_WIDTH,
  lg: SIZES.lg + SCROLLBAR_WIDTH,
  xl: SIZES.xl + SCROLLBAR_WIDTH,
}

interface SlideShowProps {
  fullWidth?: boolean
  extendMode?: 'left' | 'right' | 'no'
  minHeight?: ResponsivePropArgs<number>
}

export const ToggleButton = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  bottom: 20px;
  left: 20px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 32px;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: background-color 150ms;

  &:hover,
  &:focus {
    background-color: #000;
  }

  @media (min-width: ${VIEWPORT.lg}px) {
    width: 56px;
    height: 56px;
    bottom: 28px;
    left: 28px;
  }
`

export const StyledSlideshow = styled.div<SlideShowProps>`
  display: flex;
  position: relative;
  width: 100%;

  @media (min-width: ${VIEWPORT.md}px) {
    min-height: ${(props: SlideShowProps) => props.minHeight.md}px;
    height: 100%;
  }

  @media (min-width: ${VIEWPORT.lg}px) {
    min-height: ${(props: SlideShowProps) => props.minHeight.lg}px;
  }

  @media (min-width: ${VIEWPORT.xl}px) {
    min-height: ${(props: SlideShowProps) => props.minHeight.xl}px;
  }

  // extend full-width on XS/S, if applicable
  ${(props) =>
    props.fullWidth &&
    css`
      width: auto;
      margin-left: -16px;
      margin-right: -16px;

      @media (min-width: ${VIEWPORT.sm}px) {
        margin-left: calc((100vw - ${SIZES.sm - GUTTER_WIDTH_2X}px) * -0.5);
        margin-right: calc((100vw - ${SIZES.sm - GUTTER_WIDTH_2X}px) * -0.5);
      }

      @media (min-width: ${VIEWPORT.md}px) {
        margin-left: 0;
        margin-right: 0;
      }
    `}

  > div, .slick-slider {
    width: 100%;
    ${(props: SlideShowProps) =>
      props.extendMode &&
      props.extendMode !== 'no' &&
      css`
        @media (min-width: ${VIEWPORT.md}px) {
          position: absolute;
          ${(props: SlideShowProps) => (props.extendMode === 'left' ? 'right' : 'left')}: 0;
          top: 0;
          bottom: 0;
          width: calc(50vw - ${GUTTER_WIDTH}px);
          min-height: 100%;
        }
      `}
  }

  .slick-slider {
    height: 100%;
  }

  .slick-slide {
    aspect-ratio: 3 / 2;

    @media (min-width: ${VIEWPORT.md}px) {
      aspect-ratio: unset;
    }
  }

  .slick-track,
  .slick-list {
    height: 100%;
  }
`
